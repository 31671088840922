body {
  background: var(--surface-50);
  height: 100%;
}
html {
  height: 100%;
}
/* data entry classes */
.section {
  display: block !important;
  margin-bottom: var(--rem);
}
.section-title-row {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: var(--three-fourth-rem);
}
.section-title {
  color: var(--surface-600) !important;
  font-family: var(--lexend-regular);
  font-size: var(--font-size-xl);
  width: 50%;
}
.section-title-action {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 50%;
}
.deleteImbAccount {
  float: right;
}
.eNoteSwitch {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.disableComments {
  pointer-events: none;
  cursor: auto;
  color: var(--surface-500);
}
.eNoteSwitch {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.title-Button {
  font-size: var(--font-size-body);
  padding: var(--quarter-rem) var(--quarter-rem);
  font-family: var(--lexend-semi-bold);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: var(--border-radius);
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  font-size: var(--font-size-body);
  padding: var(--quarter-rem) var(--quarter-rem);
  background: var(--transparent);
  color: var(--primary-color);
  border: var(--reset-none);
}

.input-container-centered {
  display: flex;
  align-items: center;
}

.input-Row {
  display: flex;
  flex-wrap: wrap;
}
.input-section-seperator {
  background: var(--surface-50) !important;
  height: 2px;
  margin-bottom: var(--three-fourth-rem);
  display: flex;
  flex-wrap: wrap;
}
/* common values for all input columns */
.inputColumnFull,
.inputColumn5of6,
.inputColumn11of12,
.inputColumn3of4,
.inputColumn2of3,
.inputColumn7of12,
.inputColumn1of2,
.inputColumn5of12,
.inputColumn1of3,
.inputColumn1of4,
.inputColumn1of6,
.inputColumn1of8,
.inputColumn1of10,
.inputColumn1of12,
.inputColumn1of11,
.inputColumnAutoGrow {
  margin-right: var(--reset);
  margin-bottom: var(--three-fourth-rem);
  border-radius: var(--quarter-rem) !important;
  display: grid;
  row-gap: 0px;
  padding-right: var(--three-fourth-rem);
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  column-gap: var(--three-fourth-rem);
}
.inputColumnFull {
  /* col-12 */
  /* shared props set above */
  width: 100%;
}
.inputColumn5of6 {
  /* col-11 */
  /* shared props set above */
  width: 83.33%;
}
.inputColumn11of12 {
  /* col-10 */
  /* shared props set above */
  width: 91.66%;
}
.inputColumn3of4 {
  /* col-9 */
  /* shared props set above */
  width: 75%;
}
.inputColumn2of3 {
  /* col-8 */
  /* shared props set above */
  width: 66.66%;
}
.inputColumn7of12 {
  /* col-7 */
  /* shared props set above */
  width: 58.33%;
}
.inputColumn1of2 {
  /* col-6 */
  /* shared props set above */
  width: 50%;
}
.inputColumn5of12 {
  /* col-5 */
  /* shared props set above */
  width: 41.66%;
}
.inputColumn1of3 {
  /* col-4 */
  /* shared props set above */
  width: 33.3%;
}
.inputColumn1of4 {
  /* col-3 */
  /* shared props set above */
  width: 25%;
}
.inputColumn1of6 {
  /* col-2 */
  /* shared props set above */
  width: 16.66%;
}
.inputColumn1of8 {
  /* shared props set above */
  width: 12.5%;
}
.inputColumn1of10 {
  /* shared props set above */
  width: 10%;
}
.inputColumn1of12 {
  /* col-1 */
  /* shared props set above */
  width: 8.33%;
}
.inputColumn1of11 {
  /* shared props set above */
  width: 20.84%;
}
.inputColumnAutoGrow {
  /* no width so it will shrink */
  grid-template-rows: 14.5px auto !important;
  column-gap: var(--quarter-rem) !important;
}
.padding-right-quarterRem {
  padding-right: var(--quarter-rem) !important;
}
.padding-right-none {
  padding-right: var(--reset) !important;
}
.inputLabel {
  grid-row: 1;
  margin: 0px !important;
  padding-bottom: 4px !important;
  padding-left: 2px !important;
  padding-top: 2px !important;
}
.inputLabelCentered {
  grid-row: 2;
  margin: 0px !important;
  padding-bottom: 4px !important;
  align-items: center;
  display: flex;
  padding-left: 2px !important;
  padding-top: 2px !important;
}
.inputValue {
  grid-row: 2;
  font-family: var(--component-font-family);
  font-size: var(--font-size-body);
  color: var(--text-color);
  background: var(--white);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: var(--border-radius);
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  white-space: nowrap !important;
}
.inputDocumentContainer {
  grid-row: 2;
  font-family: var(--component-font-family);
  font-size: var(--font-size-body);
  color: var(--text-color);
  background: var(--white);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: var(--border-radius);
  display: flex !important;
  border: var(--border-1px) var(--text-color-secondary);
}
.inputDocument {
  display: flex !important;
  cursor: pointer;
  border: 0px !important;
  padding: 0px !important;
}
.inputDocumentIcon {
  flex: 0 0 auto;
  padding: var(--quarter-rem);
  width: 8.3333%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.inputDocumentAdd {
  border-color: var(--primary-color) !important;
  border-width: 1px !important;
  border-style: dotted !important;
  border-radius: var(--quarter-rem) !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.inputItemCentered {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.inputItemCentered > button {
  /* this sucks, but I can't just remove from the button or it will screw up all the old code that sucks and uses that mothod of styling */
  padding: 0px !important;
}
.required > label {
  border-top-right-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
  /* background: var(--slate-50) !important; */
  margin-left: 2px;
  margin-right: 2px;
}
.required > label::after {
  content: '*';
  padding-left: 1px;
}
/* border for input subitems (like tempAmount in orginator setup) */
.input-border-left {
  border-top-left-radius: var(--quarter-rem) !important;
  border-bottom-left-radius: var(--quarter-rem) !important;
  padding-left: 2px;
  padding-bottom: 2px;
  border-width: 1px !important;
  border-style: solid;
  border-color: var(--primary-color) !important;
}
.input-border-right {
  border-top-right-radius: var(--quarter-rem) !important;
  border-bottom-right-radius: var(--quarter-rem) !important;
  padding-left: 2px;
  padding-bottom: 2px;
  border-width: 1px !important;
  border-style: solid;
  border-color: var(--primary-color) !important;
}
.input-border-top-bottom {
  padding-left: 2px;
  padding-bottom: 2px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: var(--reset);
  border-right-width: var(--reset);
  border-style: solid;
  border-color: var(--primary-color) !important;
  border-top-right-radius: var(--reset) !important;
  border-bottom-right-radius: var(--reset) !important;
  border-top-left-radius: var(--reset) !important;
  border-bottom-left-radius: var(--reset) !important;
}
.input-border-right-none {
  border-right-width: 0px !important;
  border-right-style: none;
  border-top-right-radius: var(--reset) !important;
  border-bottom-right-radius: var(--reset) !important;
}
.input-border-left-none {
  border-left-width: 0px !important;
  border-left-style: none;
  border-top-left-radius: var(--reset) !important;
  border-bottom-left-radius: var(--reset) !important;
}
.page-layout .page-header h1,
.page-layout .page-header h1 a {
  color: var(--slate-700);
  font-size: var(--font-size-3xl);
}
.page-layout .page-header h1 a {
  background: url('./../images/agility-logo.svg') no-repeat top left;
  background-size: contain;
  padding: var(--half-rem) var(--reset) var(--half-rem) var(--three-rem);
}
.page-layout .page-header .nav,
.page-layout .page-content #adminNav.nav {
  margin: var(--reset);
  padding: var(--reset);
}

.page-layout .page-header #appNav a {
  padding: var(--main-menu-top-padding) var(--half-rem);
}
.page-layout .page-header #appNav a:hover,
.page-layout .page-header #appNav a:focus {
  background: var(--slate-400);
  border-radius: var(--rem);
  outline: var(--none);
  box-shadow: var(--none);
  color: var(--black) !important;
}
.page-layout .page-header #appNav a.active {
  background: var(--slate-200);
  border-radius: var(--rem);
  outline: var(--none);
  box-shadow: var(--none);
  color: var(--black) !important;
}
.page-layout .page-header .nav a.active {
  color: var(--black) !important;
}
.page-layout .page-header .nav li {
  display: var(--inline);
  font-size: var(--font-size-body);
}
.page-layout .page-header .nav li .pi {
  font-size: var(--font-size-body);
}
.page-layout .page-header #mobileAppNavSideBar .p-sidebar .p-sidebar-header,
.page-layout .page-header #mobileAppNavSideBar .p-sidebar .p-sidebar-content,
.page-layout .page-header #mobileAppNavSideBar .p-sidebar .p-sidebar-footer {
  padding: var(--reset);
  overflow: var(--hidden);
}
.page-layout .page-header #mobileAppNavSideBar .p-sidebar-left {
  top: var(--mobile-nav-top-pos);
  width: 30vw;
  background: var(--slate-400);
}
.page-layout .page-header #mobileAppNavSideBar .nav a:hover,
.page-layout .page-header #mobileAppNavSideBar .nav a:focus,
.page-layout .page-header #mobileAppNavSideBar .nav a.active {
  border-radius: var(--reset);
  padding: var(--reset);
  outline: var(--none);
  color: var(--black) !important;
}
.page-layout .page-header #mobileAppNavSideBar .nav li {
  display: var(--block);
  font-size: var(--rem);
}
.page-layout .page-header #mobileAppNavSideBar .nav li .pi {
  font-size: var(--rem);
}

.page-layout .page-content #adminNav {
  height: calc(100vh - 220px);
}
.page-layout .page-content #adminNav .nav li,
.page-layout .page-content #adminNav .nav li a {
  display: var(--block);
  font-size: var(--rem);
}
.page-layout .page-content #adminNav .nav li a {
  padding: var(--half-rem) var(--rem);
}
.page-layout .page-content #adminNav .nav li a.active,
.page-layout .page-content #adminNav .nav li a.active:hover,
.page-layout .page-content #adminNav .nav li a.active:focus,
.page-layout .page-content #adminNav .nav li a.active:active {
  background: var(--primary-color);
  color: var(--white);
}

.page-layout .page-content #adminNav .nav li a {
  color: var(--surface-900);
}

.page-layout .page-content #adminNav .nav li a:hover,
.page-layout .page-content #adminNav .nav li a:active,
.page-layout .page-content #adminNav .nav li a:focus {
  color: var(--black);
}

.page-layout .page-content {
  margin-top: var(--page-content-top-margin);
}
.page-layout .page-content .page-left-column {
  height: calc(100vh - 125px);
  width: 253px;
  z-index: 100;
  padding-left: var(--one-half-rem) !important;
  background: var(--surface-50);
}
.page-layout .page-content .page-content-column {
  left: 277px;
  width: calc(100vw - 277px);
  min-width: 1119px;
}

.page-layout .page-content .sidebar-open .loan-page.page-content-column {
  left: 273px;
  width: calc(84vw - 328px);
  min-width: 0;
}

.page-layout .page-content .sidebar-close .loan-page.page-content-column {
  left: 273px;
  width: calc(84vw - 106px);
  min-width: 0;
}

.page-layout .page-content .page-right-column {
  height: calc(100vh - 133px);
  right: var(--one-half-rem);
}
.page-layout .page-footer {
  font-size: var(--font-size-sm);
  color: var(--text-color);
}
.page-layout .page-footer .nav {
  margin: var(--reset);
  padding: var(--reset);
}
.page-layout .page-footer .nav li {
  display: var(--inline);
}
.page-layout .page-footer .nav li a {
  color: var(--slate-600);
}
.page-layout .page-footer .copyright {
  margin: var(--reset);
}
.page-layout.secure {
  background: var(--surface-50);
}
.page-layout.secure .page-header {
  background: var(--primary-color);
  box-shadow: var(--header-box-shadow);
}
.page-layout.secure .page-header h1,
.page-layout.secure .page-header h1 a {
  color: var(--white);
}
.page-layout.secure .page-header #hamBurgerMenuIcon {
  background: var(--transparent);
  border: var(--reset-none);
}
.page-layout.secure .page-header #hamBurgerMenuIcon .p-button-icon {
  font-size: var(--font-size-4xl);
}
.page-layout.banner .page-content {
  padding-top: var(--one-half-rem) !important;
}
.page-layout .page-content-vh {
  min-height: calc(100vh - 186px);
}
.col-state-card {
  flex: 0 0 auto;
  padding: var(--half-rem);
  width: 16.66%;
}

.page-layout .page-content .status-card-content,
.page-layout .page-content .loans-queue-content,
.page-layout .page-content .state-licenses,
.page-layout .page-content .settlement-request-loans,
.page-layout .page-content .admin-nav {
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.page-layout .page-content .status-card-content {
  height: calc(100vh - 277px);
}

.page-layout .page-content .admin-nav {
  height: calc(100vh - 185px);
}

.page-layout .page-content .loans-queue-content {
  max-height: calc(100vh - 250px);
}
.page-layout .page-content .state-licenses {
  max-height: 195px;
}

.page-layout .page-content .sidebar-close {
  left: 30px;
  width: calc(100vw - 52px);
  min-width: 1119px;
  padding-right: var(--one-half-rem);
  padding-left: var(--one-half-rem);
  position: relative;
}

.page-layout
  .page-content
  .sidebar-close
  #funding-pipeline
  .custom-loan-scroller {
  width: calc(100vw - 90px);
}

.page-layout .page-content .sidebar-open {
  left: 253px;
  width: calc(100vw - 292px);
  min-width: 1119px;
  position: relative;
  padding-left: var(--one-half-rem);
  margin-top: -2px;
}
.page-layout .page-content .sidebar-open #landing-page {
  margin-top: 5px;
}
.page-layout .page-content .sidebar-open.securityLog {
  width: calc(100vw - 295px) !important;
}
.page-layout .page-content .sidebar-open.funding-pipeline,
.page-layout .page-content .sidebar-open.reporting {
  width: calc(100vw - 292px) !important;
}
.page-layout .page-content .sidebar-close.funding-pipeline,
.page-layout .page-content .sidebar-close.reporting {
  width: calc(100vw - 67px) !important;
}

.page-layout .page-content .settlement-request-loans {
  max-height: 235px;
}

.page-layout .page-content .status-card-content::-webkit-scrollbar,
.page-layout .page-content .admin-nav::-webkit-scrollbar,
.page-layout .page-content .loans-queue-content::-webkit-scrollbar,
.page-layout .page-content .state-licenses::-webkit-scrollbar,
.page-layout .page-content .settlement-request-loans::-webkit-scrollbar,
#funding-pipeline .cards-container::-webkit-scrollbar,
.roles-permisions-table .p-datatable-wrapper::-webkit-scrollbar {
  display: none;
}
#funding-pipeline .cards-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  flex: 1 1 0% !important;
}
.loan-card p-checkbox .p-checkbox-box {
  border-radius: 50%;
}
#loans-container::after {
  content: '';
  opacity: 0.65;
  background: #f5f7fb;
  position: absolute;
  right: -25%;
  top: 0;
  height: 100%;
  width: 25%;
  z-index: 1;
}
#loans-container::before {
  content: '';
  opacity: 0.65;
  background: #f5f7fb;
  position: absolute;
  left: -25%;
  top: 0;
  height: 100%;
  width: 25%;
  z-index: 1;
}
#funding-pipeline .custom-loan-scroller {
  width: 100%;
  height: 5var (--reset);
}
#funding-pipeline .custom-dropdown-width {
  padding: var(--reset) !important;
  background-color: var(--white);
  color: var(--primary-color) !important;
  background: var(--white) !important;
  width: 100px;
}
#funding-pipeline .custom-calendar-width {
  width: 120px;
}
#funding-pipeline .p-multiselect-label {
  padding: 4px;
}
#funding-pipeline .p-multiselect-header {
  display: none;
}
#funding-pipeline .p-multiselect-trigger {
  display: none;
}
#funding-pipeline p-checkbox .p-checkbox-box {
  border-radius: 50%;
}
#facility-level-fees .p-multiselect-trigger {
  display: none;
}
.loan-file-import-table .p-datatable-wrapper {
  overflow: visible !important;
}
.status-card-content .background-stripes {
  background: repeating-linear-gradient(
    -45deg,
    #e1faeb,
    #ffffff 4px
  ) !important;
}
p-confirmdialog[key='submitLoansDialog'] .p-dialog-mask.p-component-overlay {
  pointer-events: none;
}

p-confirmdialog[key='submitLoansDialog'] .p-confirm-dialog-reject {
  margin-left: var(--font-size-sm);
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
}

.custom-scroll::-webkit-scrollbar:horizontal {
  height: 6px;
}

.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
}

.document.disabled {
  background-color: #eceff5 !important;
  pointer-events: none;
}
.add-wire-dialog .return-from-ca-checkbox {
  right: 60px;
  top: 20px;
  position: absolute;
}
.documentLabel.disabled {
  color: var(--slate-200) !important;
  pointer-events: none;
}
#additionalActionsBtnSmall {
  position: absolute;
  top: 0;
  right: 0;
  scale: 0.6;
}
#additionalActions {
  scale: 0.6;
}
.additionalCardActionsMenu.p-menu .p-menu-list > li:last-child a span {
  color: var(--danger) !important;
}
.additionalCardActionsMenu.p-menu .p-menu-list > li:last-child:hover a span {
  color: var(--white) !important;
}
#depositComment.p-inputtextarea {
  height: 80px !important;
  margin-top: 4px;
}
#loan-request-documents p-dropdown .p-dropdown .p-dropdown-label {
  padding-left: 0;
  font-size: var(--font-size-sm) !important;
  color: var(--primary-color) !important;
}
#loan-condition-buttons p-togglebutton:hover span {
  color: var(--white);
}
.p-button.p-button-outlined.active {
  background: var(--slate-600);
  border-color: var(--slate-600);
  color: var(--white);
}
.roleActionsMenu.p-menu .p-menu-list > li:last-child a span {
  color: var(--danger);
}
.roleActionsMenu.p-menu .p-menu-list > li:last-child a.p-disabled span {
  color: var(--surface-500);
}
.left-1rem {
  left: var(--rem) !important;
}
.left-2rem {
  left: var(--two-rem) !important;
}
.width-2rem {
  width: var(--two-rem) !important;
}
.min-width-2rem {
  min-width: var(--two-rem) !important;
}
.max-width-2rem {
  max-width: var(--two-rem) !important;
}
.width-5rem {
  width: 5rem !important;
}
.min-width-5rem {
  min-width: 5rem !important;
}
.max-width-5rem {
  max-width: 5rem !important;
}
.width-9rem {
  width: 9rem !important;
}
.width-10rem {
  width: 10rem !important;
}
.width-15rem {
  width: 15rem !important;
}
.width-25rem {
  width: 25rem !important;
}
.min-width-15rem {
  min-width: 15rem !important;
}
.max-width-15rem {
  max-width: 15rem !important;
}
.float-right {
  float: right;
}
.rotate-90-deg {
  transform-origin: 0 0;
  transform: rotate(90deg);
  margin-left: 2.125rem;
  margin-top: var(--half-rem);
}
p-dynamicdialog .add-new-role .p-dialog-content {
  overflow: visible;
}
#permissions-banner .pi-lock,
#permissions-banner .pi-times,
#permissions-banner .content {
  font-size: smaller !important;
}
.permission-restricted {
  cursor: not-allowed !important;
}
.permission-restricted .button-primary {
  opacity: var(--alpha-100);
  background: var(--surface-300) !important;
  border: 3px solid var(--surface-300) !important;
  color: var(--white) !important;
}
.permission-restricted .button-secondary {
  background: var(--transparent) !important;
  border: 3px solid var(--surface-300) !important;
  color: var(--surface-300) !important;
}
.permission-restricted > label,
.permission-restricted > label span,
.permission-restricted > p-button > button {
  color: var(--surface-300) !important;
}
.permission-restricted .button-text,
.permission-restricted .button-text label span,
.permission-restricted .button-text label i,
.permission-restricted .p-inputswitch-slider,
.permission-restricted label,
.permission-restricted button,
.permission-restricted a,
.permission-restricted a span,
.permission-restricted div,
.p-dropdown-item.p-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
}
.permission-restricted .button-primary label span {
  color: var(--white) !important;
}
td .permission-restricted a,
.permission-restricted button label span {
  color: var(--surface-300) !important;
}
.permission-restricted .p-button.p-button-text {
  color: var(--surface-300) !important;
  pointer-events: none;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item .permission-restricted {
  color: var(--surface-300);
  cursor: not-allowed !important;
}
.permission-restricted p-button,
.permission-restricted button {
  pointer-events: none !important;
}
.permission-restricted a.p-menuitem-link:hover,
.permission-restricted a.p-menuitem-link:focus,
.permission-restricted a.p-menuitem-link .p-menuitem-icon,
.permission-restricted a.p-menuitem-link .p-menuitem-text {
  background: var(--surface-0) !important;
  color: var(--surface-300) !important;
}
li.permission-restricted a {
  color: var(--surface-300) !important;
}
li.permission-restricted.p-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
th.permission-restricted {
  color: var(--surface-300) !important;
  cursor: not-allowed !important;
}
#sidebarMenu {
  background: var(--surface-200);
  width: 253px;
  z-index: 100;
}
#sidebarMenu .p-panelmenu-icon {
  order: 1;
  margin-left: auto;
  margin-right: 0;
  padding-right: 8px;
}
#sidebarMenu .p-panelmenu .p-panelmenu-header > a {
  padding: 8px;
  padding-right: 0px;
  color: var(--primary-color);
  border: none;
  background: none;
}
#sidebarMenu .p-panelmenu .p-panelmenu-header.p-highlight > a {
  color: var(--primary-color);
  background: none;
  border: none;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
#sidebarMenu .p-panelmenu .p-menuitem-badge {
  color: var(--text-color);
  background: none;
  margin: 0;
}
#sidebarMenu .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0px;
  padding-left: 4px;
  justify-content: space-between;
}

#sidebarMenu .p-panelmenu .p-panelmenu-content {
  padding: 0px;
  padding-bottom: 8px;
  border: none;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

#sidebarMenu .p-panelmenu .p-panelmenu-content .p-submenu-list {
  margin-left: 27px;
}

#sidebarMenu .p-panelmenu-panel {
  margin-bottom: 4px;
  background: white;
  border-radius: var(--border-radius);
}

.accountAdditionalActionsMenu.p-menu .p-menu-list > li.block a span {
  color: var(--surface-500) !important;
}
.accountAdditionalActionsMenu.p-menu .p-menu-list > li:last-child a span {
  color: var(--danger) !important;
}
.accountAdditionalActionsMenu.p-menu .p-menu-list > li:last-child:hover a span {
  color: var(--danger) !important;
}
.disabledMultiSelect .p-multiselect.p-disabled.p-multiselect-chip {
  background: var(--surface-100) !important;
}
.commentsGroupBtns {
  position: relative;
  left: 23px;
}
#sidebarMenu
  .p-panelmenu-panel
  .p-menuitem-link
  span.p-menuitem-icon:nth-child(1) {
  order: 1;
}
#sidebarMenu
  .p-panelmenu-panel
  .p-menuitem-link
  span.p-menuitem-text:nth-child(2) {
  order: 0;
}
.portfolioCards-container {
  margin-bottom: var(--half-rem) !important;
  flex-wrap: wrap;
  text-align: center !important;
  display: flex !important;
}
.portfolio-container {
  cursor: pointer !important;
  overflow: hidden !important;
  min-height: 100% !important;
  padding: var(--quarter-rem) !important;
  background: var(--white);
  box-shadow: var(--overlay-box-shadow);
  border-radius: var(--border-radius);
  color: var(--surface-900) !important;
}
.current-portfolio-title {
  margin: var(--reset) !important;
  padding-bottom: var(--rem) !important;
  font-size: var(--font-size-xl);
}

.portfolioCards-container .col-3 .portfolio-container {
  box-shadow: none;
}
.portfolioConditionsLoanCard {
  border-bottom-color: var(--warning);
  border-bottom-style: solid;
  border-bottom-width: 9px;
  background-color: var(--warning-light);
}
.portfolioPriorityLoanCard {
  border-bottom-color: var(--danger);
  border-bottom-style: solid;
  border-bottom-width: 9px;
  background-color: var(--danger-light);
}
.portfolioFundedLoanCard {
  border-bottom-color: var(--slate-500);
  border-bottom-style: solid;
  border-bottom-width: 9px;
  background-color: #e6f4f8;
}
.count-container {
  margin-left: var(--rem);
  margin-right: var(--rem);
  padding-top: var(--half-rem);
  padding-bottom: var(--half-rem);
  padding-left: var(--quarter-rem);
  padding-right: var(--quarter-rem);
  font-size: var(--font-size-4xl);
  display: flex;
  font-family: var(--lexend-semi-bold);
}
.portfolio-title-container {
  margin-left: var(--rem);
  margin-right: var(--rem);
  padding-top: var(--half-rem);
  padding-bottom: var(--half-rem);
  padding-left: var(--quarter-rem);
  padding-right: var(--quarter-rem);
  font-size: var(--font-size-lg);
  display: flex;
  text-align: left;
}
.current-portfolio-container {
  flex-wrap: wrap;
  text-align: center;
  display: flex;
}
.current-portfolio-container #totalCommitments .portfolio-container,
.current-portfolio-container #noOfClients .portfolio-container,
.current-portfolio-container #noOfLoans .portfolio-container,
.current-portfolio-container #avgUtilization .portfolio-container,
.current-portfolio-container #mTDFunded .portfolio-container,
.current-portfolio-container #mTDOfLoans .portfolio-container,
.current-portfolio-container #avgLTV .portfolio-container {
  box-shadow: none !important;
}
.current-portfolio-container .portfolio-container,
.current-portfolio-container .portfolio-title-container,
.current-portfolio-container .count-container {
  background: var(--surface-100) !important;
  justify-content: center !important;
}
#sidebarMenu
  .p-panelmenu-panel
  .p-menuitem-link
  span.p-menuitem-badge:nth-child(3) {
  order: 2;
  display: flex;
  justify-content: end;
  flex-shrink: 0;
}
#sidebarMenu .p-panelmenu-panel .p-menuitem-link span.p-menuitem-text {
  flex-basis: 90%;
}
#sidebarMenu
  .p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover {
  background: var(--surface-50);
}
#sidebarMenu
  .p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:focus {
  outline: none;
  outline-offset: unset;
  box-shadow: none;
  background: var(--slate-50);
}
#dashboard-top-clients #client-card:nth-child(2) .card {
  border-bottom: 5px solid #ffb900;
}
#dashboard-top-clients #client-card:nth-child(3) .card {
  border-bottom: 5px solid var(--success);
}
#dashboard-top-clients #client-card:nth-child(4) .card {
  border-bottom: 5px solid #00a4ef;
}
#dashboard-top-clients #client-card:nth-child(5) .card {
  border-bottom: 5px solid var(--black);
}
#dashboard-top-clients #client-card:nth-child(6) .card {
  border-bottom: 5px solid #f25022;
}
#curtailment .p-multiselect .p-multiselect-label {
  padding: 4px;
}
#curtailment .p-multiselect {
  width: 100px !important;
  overflow: hidden;
}
#curtailment .p-multiselect-trigger {
  display: none;
}
.hr-seperator {
  height: 1px;
  position: absolute;
  top: -6px;
  left: 10%;
  width: 80%;
}
.user-menu .p-menu-overlay {
  top: 63px !important;
}
.p-toast-message-content > div {
  overflow: hidden;
  width: 0.9167%;
}
.card-title {
  margin: var(--reset);
  padding: var(--reset);
  line-height: var(--h4-line-height);
  color: var(--surface-600) !important;
  font-family: var(--lexend-light);
  font-size: var(--font-size-3xl);
  flex: 1 1 0% !important;
}
.bubble {
  width: var(--one-half-rem);
  height: var(--one-half-rem);
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-tabview-nav-container {
  margin-bottom: var(--three-fourth-rem);
}
p-tabView#dashboard .p-tabview-nav-container {
  position: absolute;
  top: -47px;
}
.tenant-user p-tabView#dashboard .p-tabview-nav-container {
  right: 4px;
}
.subtenant-user p-tabView#dashboard .p-tabview-nav-container {
  right: 275px;
}
p-tabView#dashboard .p-tabview-nav-container li {
  background: var(--transparent);
  border: none !important;
  border-top: 1px solid var(--primary-color) !important;
  border-right: 1px solid var(--primary-color) !important;
  border-bottom: 1px solid var(--primary-color) !important;
  padding: 0;
}
p-tabView#dashboard .p-tabview-nav-container li:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid var(--primary-color) !important;
}
p-tabView#dashboard .p-tabview-nav-container li:nth-last-child(2) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

p-tabView#dashboard .p-tabview-nav-container li:nth-child(5) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
p-tabView#dashboard .p-tabview-nav-container li > a {
  margin: 1px 0;
  padding: var(--quarter-rem) var(--rem);
  color: var(--primary-color) !important;
  border: none;
}
p-tabView#dashboard .p-tabview-nav-container li > a:active,
p-tabView#dashboard .p-tabview-nav-container li > a:focus {
  outline: none;
  outline-offset: none;
  box-shadow: none;
}
p-tabView#dashboard .p-tabview-nav-container li.p-highlight {
  outline: var(--reset-none);
  outline-offset: var(--reset);
  box-shadow: var(--focus-ring);

  background: var(--slate-600);
  border-color: var(--slate-600);
}
p-tabView#dashboard .p-tabview-nav-container li:hover {
  background: var(--slate-600);
}
p-tabView#dashboard .p-tabview-nav-container li:hover > a,
p-tabView#dashboard .p-tabview-nav-container li.p-highlight > a {
  color: var(--white) !important;
}
p-carousel#imb-carousel .p-carousel-prev {
  margin: 32px 16px 32px 32px;
  color: var(--primary-color);
}
p-carousel#imb-carousel .p-carousel-next {
  margin: 32px 32px 32px 16px;
  color: var(--primary-color);
}
p-carousel#imb-carousel .p-carousel-prev:enabled:hover,
p-carousel#imb-carousel .p-carousel-next:enabled:hover {
  color: var(--surface-0) !important;
}
p-carousel#imb-carousel .p-carousel-prev-icon.pi.pi-chevron-left,
p-carousel#imb-carousel .p-carousel-prev-icon.pi.pi-chevron-right {
  font-size: x-large;
}
p-carousel#imb-carousel .p-carousel-items-content {
  padding: 24px 0;
}
p-carousel#imb-carousel .p-carousel-item {
  position: relative;
}
p-carousel#imb-carousel .p-carousel-item .selected::after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid var(--primary-color);
  position: absolute;
  bottom: -14px;
  left: 50%;
  margin-left: -6px;
  cursor: auto;
}
#left-sidebar.p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--slate-700);
}
#left-sidebar .p-multiselect {
  width: 100%;
}
#sidebarMenu .p-panelmenu .p-panelmenu-header.p-highlight > a {
  box-shadow: none;
}
#left-sidebar .icon i:hover,
#left-sidebar .icon.active i {
  background-color: var(--slate-100);
  color: var(--primary-color);
}
#messageBanner .p-toast {
  background: #d6246e !important;
  padding: 0 !important ;
  color: var(--white);
  font-family: Lexend Semi Bold;
  font-size: 14px;
  position: unset;
  width: unset;
}
#messageBanner .p-toast-message-content {
  padding: 0;
  text-align: -webkit-center;
  align-items: center;
  height: 24px;
}
#messageBanner .p-toast-icon-close {
  margin-right: 38px;
  height: 8px;
  width: 8px;
  color: var(--white);
}
#messageBanner .p-toast .p-toast-message {
  box-shadow: none;
}
#messageBanner
  .p-toast
  .p-toast-message
  .p-toast-message-content
  .p-toast-detail {
  margin: 0;
}
.exportIcon span.p-button-icon {
  font-size: x-large;
}
.user-name {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  white-space: nowrap !important;
}
.history-icon-container {
  width: var(--one-half-rem);
  height: var(--one-half-rem);
}
.history-icon {
  font-size: x-small;
}
.uerRolesPermissionsContainer {
  width: 100%;
  height: 380px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: auto;
  align-content: flex-start;
}

.permissionsContainer {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.multiFactAuthContainer {
  margin-right: var(--rem);
}

.deteleCommentButton .p-button-text:hover {
  color: var(--danger) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.p-chips.w-full .p-chips-multiple-container {
  width: 100%;
}

.sidebar-container {
  z-index: 10000;
  background: var(--surface-200);
}

.margin-x-auto {
  margin: 0 auto;
}
p-confirmdialog .p-confirm-dialog {
  width: 26.25rem;
}
.pMenuCancel {
  margin-top: var(--half-rem) !important;
}
.article {
  position: relative;
  margin: 0;
  padding: 0;
}
#account-page .treasuryCalenderWidth {
  width: 130px;
}
@media screen and (min-width: 589px) {
  .page-layout .sm\:page-content-vh {
    min-height: calc(100vh - 172px);
  }
}
@media screen and (min-width: 768px) {
  .page-layout .sm\:page-content-vh {
    min-height: calc(100vh - 164px);
  }
}
@media screen and (max-width: 1120px) {
  .page-layout .page-header #mobileAppNavSideBar .p-sidebar-left {
    width: 25vw;
  }
}
@media screen and (max-width: 930px) {
  .page-layout .page-header #mobileAppNavSideBar .p-sidebar-left {
    width: 30vw;
  }
}
@media screen and (max-width: 719px) {
  .page-layout .page-header #mobileAppNavSideBar .p-sidebar-left {
    width: 40vw;
  }
}
@media screen and (max-width: 550px) {
  .page-layout .page-header #mobileAppNavSideBar .p-sidebar-left {
    width: 100vw;
  }
}
@media screen and (max-width: 1400px) {
  .page-layout .page-content .page-right-column {
    position: relative !important;
    width: 240px;
    right: -24px !important;
  }
  .page-layout .page-content .sidebar-open .loan-page.page-content-column {
    width: 830px;
  }
}
@media screen and ((max-width: 1168px)) {
  .page-layout .page-content .sidebar-close .loan-page.page-content-column {
    width: 830px;
  }
}

.header-container {
  padding: var(--reset);
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  margin-top: 16px;
}
.header-title {
  flex: 1 1 0%;
  align-self: flex-start;
  line-height: var(--h4-line-height);
  font-family: var(--lexend-light);
  font-size: var(--font-size-5xl);
  margin: var(--reset);
  padding: var(--reset);
  margin-bottom: var(--half-rem);
}
.grid-m {
  margin: var(--reset);
  display: flex;
  flex-wrap: wrap;
}
.dashboard-card {
  display: flex;
  flex: 0 0 auto;
  width: 100%;
}
.dashboard-card > div {
  flex: 0 0 auto;
  padding: var(--half-rem);
  width: 33.333%;
  box-sizing: border-box;
}
.dashboard-card > div:first-child {
  padding-left: var(--reset);
  padding-right: var(--rem);
  padding-top: var(--reset);
}
.dashboard-card > div:nth-child(2) {
  padding-left: var(--half-rem);
  padding-right: var(--rem);
  padding-top: var(--reset);
}
.dashboard-card > div:last-child {
  padding-left: var(--half-rem);
  padding-right: var(--reset);
  padding-top: var(--reset);
}
.outer-container {
  position: relative;
  margin: var(--reset);
  padding-left: var(--reset) !important;
  padding-right: var(--reset) !important;
  display: flex;
  flex: 0 0 auto;
  padding: var(--half-rem);
  width: 100%;
}
.inner-container {
  padding-right: var(--half-rem) !important;
  align-items: center;
  display: flex;
}
.flex-align {
  display: flex;
  align-items: center;
}

.col-12 #selectCheckboxHeader {
  width: var(--two-rem);
  margin-right: var(--reset);
  padding-right: var(--reset);
  white-space: nowrap;
}
.tableHearderCommon {
  width: 7rem;
  margin-right: var(--reset);
  white-space: nowrap;
}

.multiselect-option {
  vertical-align: middle;
  display: inline-block;
}
.multiselect-option > span {
  margin-left: var(--quarter-rem) !important;
  margin-top: var(--quarter-rem) !important;
}
#dtLoansBulkView .p-selectable-row td > div {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  align-items: center;
}
.text-right-p-border {
  width: 9rem !important;
  padding-right: var(--two-rem) !important;
  text-align: right !important;
  border-color: var(--surface-200);
}
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--three-fourth-rem);
}
.export-button-style {
  margin-left: var(--half-rem) !important;
  border-radius: var(--pct-50);
  background: var(--transparent) !important;
  color: var(--primary-color);
  border: var(--border-3px);
  border-radius: var(--two-rem);
  font-size: var(--font-size-body);
  padding: var(--half-rem) var(--half-rem);
  height: var(--two-quarter-rem);
}

.export-button-style:disabled {
  color: var(--slate-200) !important;
  cursor: not-allowed;
}

.export-button-style:not(:disabled):hover {
  background: var(--primary-color) !important;
  color: var(--white) !important;
  border-color: var(--transparent) !important;
}
.mr-mb {
  margin-bottom: var(--reset) !important;
  margin-right: var(--quarter-rem) !important;
}
.flex-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.table-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--half-rem) !important;
}
.settings-btn {
  font-size: var(--font-size-body);
  padding: var(--half-rem) var(--half-rem);
  background: var(--transparent);
  color: var(--primary-color);
  border: var(--border-3px);
  border-radius: var(--pct-50);
  height: var(--trigger-icon-rem);
  margin-left: var(--half-rem) !important;
}
.settings-btn:hover,
.settings-btn:focus {
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
  border-color: var(--transparent) !important;
}
.selection-field {
  border-radius: 0.375rem !important;
  margin: var(--reset) !important;
  padding: var(--quarter-rem) !important;
  margin-bottom: var(--rem);
}
.selection-field #reports,
.selection-field #usersGroup {
  background: var(--slate-50);
}
.selection-field label {
  display: inline-block;
  margin-bottom: var(--half-rem);
}
.search-container {
  padding: var(--reset) !important;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex: 0 0 auto;
  width: 25%;
}
.add-btn-container {
  margin: var(--reset) !important;
  padding-right: var(--reset) !important;
  justify-content: flex-end;
  display: flex;
  padding-bottom: var(--rem) !important;
}
.add-btn-inner-container {
  margin: var(--reset) !important;
  margin-top: var(--quarter-rem) !important;
  margin-bottom: var(--quarter-rem) !important;
  padding-right: var(--reset) !important;
  text-align: right;
}
@media screen and (min-width: 768px) {
  .page-layout .grid-m {
    min-height: calc(100vh - 164px);
  }
}
@media screen and (min-width: 589px) {
  .page-layout .grid-m {
    min-height: calc(100vh - 172px);
  }
}
.page-layout .grid-m {
  min-height: calc(100vh - 186px);
}
.card-style {
  padding: var(--one-half-rem) !important ;
  padding-top: var(--rem) !important;
  padding-bottom: var(--reset) !important;
  box-sizing: border-box;
  background: var(--white);
  box-shadow: var(--overlay-box-shadow);
  border-radius: var(--border-radius);
  font-family: var(--component-font-family);
  font-size: var(--font-size-body);
}
.card-style .p-card-body {
  padding: var(--reset) !important;
}
.div-container {
  border-radius: 0.375rem !important;
  margin: var(--reset) !important;
  padding: var(--quarter-rem) !important;
  margin-bottom: var(--rem);
  box-sizing: border-box;
  background: var(--slate-50);
}
.form-group-div {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * var(--half-rem));
  margin-left: calc(-1 * var(--half-rem));
  margin-top: calc(-1 * var(--half-rem));
}
.tabs-list .p-tabview-nav-container {
  margin-top: var(--three-fourth-rem) !important;
}
.column-12-padding {
  padding: var(--reset) !important;
  flex: 0 0 auto;
  width: 100%;
}
.td-center-align {
  text-align: center !important;
}
